import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { Storage } from '@ionic/storage';

@Injectable()

export class PersonalService implements OnInit {
    token: string;
    headersJson: HttpHeaders;

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private storage: Storage
    ) {
        this.storage.ready().then(resp => {
            this.storage.get('token').then(token => {
                this.token = token;
            });
        });
    }

    ngOnInit() {
        this.headersJson = new HttpHeaders({
            Accept: 'application/json',
            'Content-type': 'application/json'
        });
    }

    setAvatar(avatar, token) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(`${environment.wpv2Endpoint}/users/me`, avatar, {headers});
    }

    retriveUser(token) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(`${environment.wpv2Endpoint}/users/me`, {headers});
    }

    updateCustomer(id, customer) {
        return this.http.put('customers/' + id, customer, {headers: this.headersJson});
    }

    getCity(city = '') {
        let params = '';
        if (city) {
            params = `?city=${city}`;
        }
        return this.http.get('wp-json/opt/cities' + params);
    }

    deleteAccount(id: number): Observable<any> {
        const body = {
          user_id: id
        };
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.http.post('wp-json/rs/deleteaccount', body, {headers}).pipe(
          catchError((error) => {
            this.alertService.presentAlert(error);
            return throwError(error);
          })
        );
    }
}
