import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// dev api
// import dummyLocationsData from '../../../assets/json/dummy-locations.json';
// prod api
import dummyLocationsData from '../../../assets/json/dummy-locations-prod.json';
import { environment } from '../../../environments/environment';
import { LocalizationService } from './localization.service';
import { Subject, ReplaySubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from './alert.service';

const LNG_KEY = 'SELECTED_LANGUAGE';
const CITY_KEY = 'SELECTED_CITY';

@Injectable({
    providedIn: 'root'
})
export class LanguageCityService {
    selectedLanguage = '';
    selectedCity = '';
    selectedData: any = {};
    dummyLocationsData: any;
    cityData: any;
    appCities = [];
    changeCity: any;
    changeCity$: Subject<any> = new Subject<any>();
    optionsLoaded = false;
    getOptions$: ReplaySubject<any> = new ReplaySubject<any>(1);
    public getMap$: ReplaySubject<any> = new ReplaySubject<any>(1);

    constructor(
        private storage: Storage,
        private localizationService: LocalizationService,
        private http: HttpClient,
        private alertService: AlertService
    ) {
    }

    /* on app load - digging into storage - if we have saved city there
    * pass it to resolver, if not - setting default city Lviv and passing data to resolver
    * that we have to do geolocation or ask user to choose the city
    *  */
    setInitialAppCity() {
        return this.storage.ready().then(() => {
            return this.storage.get(CITY_KEY).then(city => {
                if (city) {
                    this.setCity(city, true);
                    this.selectedData.toGeolocate = false;
                    return city;
                } else {
                    this.selectedData.toGeolocate = true;
                }
            });
        });
    }

    /* on app load - digging into storage - if we have saved language there
    * pass it to resolver, if not - setting default ua lang */
    setInitialAppLanguage() {
        return this.storage.ready().then(() => {
            return this.storage.get(LNG_KEY).then(lang => {
                if (lang) {
                    if (lang === 'ru') { lang = 'uk'; }
                    this.setLang(lang, true);
                    return lang;
                } else {
                    this.setLang('uk', true);
                    return 'uk';
                }
            });
        });
    }

    /* promises chain to wait for all initial steps to be done */
    setInitialCityAndLanguage() {
        return this.setInitialAppLanguage().then(result => {
            this.selectedData.language = result;
            this.localizationService.setDefaultLang(this.selectedData.language); //
            this.localizationService.setLanguage(this.selectedData.language); //
        }).then(() => {
            return this.setInitialAppCity().then(data => {
                this.setChangeCity(dummyLocationsData.locations, data);
                this.getData();
                this.appCities = dummyLocationsData.locations[`${this.selectedData.language}`];
                this.appCities.find(x => x.value === data ? this.selectedData = { ...x, ...this.selectedData } : null);
                return this.selectedData;
            });
        });
    }

    // set both
    setBoth(lng, city, initial) {
        if (lng === 'ru') { lng = 'uk'; }
        this.storage.set(LNG_KEY, lng).then(() => {
            this.selectedLanguage = lng;
            this.storage.set(CITY_KEY, city).then(() => {
                this.selectedCity = city;
                this.setChangeCity(dummyLocationsData.locations, city);
                this.changeCity$.next();
                if (!initial) {
                    this.appReload();
                }
            });
        });
    }

    // set app language
    setLang(lng, initial) {
        if (lng === 'ru') { lng = 'uk'; }
        this.storage.set(LNG_KEY, lng).then(() => {
            this.selectedLanguage = lng;
            if (!initial) {
                this.appReload();
            }
        });
    }

    // set app city
    setCity(city, initial) {
        this.storage.set(CITY_KEY, city).then(() => {
            this.selectedCity = city;
            this.setChangeCity(dummyLocationsData.locations, city);
            this.changeCity$.next();
            if (!initial) {
                this.appReload();
            }
        });
    }

    appReload() {
        this.storage.remove('stick_ginger');
        this.storage.remove('cart').then(() => {
            // @ts-ignore
            window.location = window.location.origin;
        });
    }

    getAppCities() {
        return this.appCities;
    }

    get() {
        return this.selectedData;
    }

    setChangeCity(locations, city) {
        this.changeCity = locations[this.selectedData.language].find(x => x.value === city);
    }

    async getData() {
        return this.http.get(`${environment.acfEndpoint}/options/options`).toPromise().then((result: any) => {
            this.getOptions$.next(result);
            if (!this.optionsLoaded) {
                this.getGoogleMaps(result?.acf?.google_map_key ? result?.acf?.google_map_key : 'AIzaSyB5zjwaw76cIJBrSUxSBHFb8LNo5IHw_VY');
            }
            this.optionsLoaded = true;
        });
    }

    getGoogleMaps(mapApiKey: string) {
        this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&libraries=geometry,places`, 'callback')
        // &libraries=geometry,circle,places&language=uk
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        )
        .subscribe(
          (data: any) => {
            this.getMap$.next(data);
          },
          (error: any) => {
            this.alertService.presentAlert(error);
          }
        );
    }

    get getMap() {
        return this.getMap$;
    }
}
