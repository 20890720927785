import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PopupPage } from './components/popup/popup.page';
import { WoocommerceCustomerService, WoocommerceOrderService } from 'src/app/core/services/wooapi/wooApi';
import { PersonalService } from '../home/personal/personal.service';
import { DatePointPipe } from './pipes/date-point.pipe';
import { CityPipe } from './pipes/city.pipe';
import { RespondPopupComponent } from './components/respond-popup/respond-popup.component';
import { PhotoService } from '../core/services/photo.service';
import { RespondThanksPopupComponent } from './components/respond-thanks-popup/respond-thanks-popup.component';
import { PhoneDirective } from './directives/phone/phone.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteInputModule } from './modules/autocomplete-input/autocomplete-input.module';
import { UpdatePopoverComponent } from './components/update-popover/update-popover.component';
import { MapComponent } from './components/map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DeliveryZonePopoverComponent } from './components/delivery-zone-popover/delivery-zone-popover.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule,
        AutocompleteInputModule,
        GoogleMapsModule
    ],
    declarations: [
        PopupPage,
        RespondPopupComponent,
        DatePointPipe,
        CityPipe,
        RespondThanksPopupComponent,
        PhoneDirective,
        UpdatePopoverComponent,
        MapComponent,
        DeliveryZonePopoverComponent
    ],
    providers: [WoocommerceOrderService, WoocommerceCustomerService, PersonalService, PhotoService],
    exports: [DatePointPipe, CityPipe, PhoneDirective, AutocompleteInputModule, MapComponent],
    entryComponents: [PopupPage, RespondPopupComponent],
})
export class SharedModule {
}
