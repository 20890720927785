import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { LanguageCityService } from 'src/app/core/services/language-city.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() height = '300px';
  @Input() center: google.maps.LatLngLiteral = {
    lat: 49.836768,
    lng: 24.034245,
  };
  @Input() zoom = 11;
  @Input() address: string;
  @Input() markerArray: Array<google.maps.MarkerOptions> = [];
  @Output() currentTerminal: EventEmitter<any> = new EventEmitter<any>();
  @Input() mapZonesArray: Array<any>;
  @Input() reset: boolean = false;
  @Input() mapTheme: Array<google.maps.MapTypeStyle> = [];
  @ViewChild('maps', { static: false }) maps: any;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  mapLoaded: boolean;
  // Map settings
  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: this.zoom,
    maxZoom: 16,
    minZoom: 3,
    draggable: true,
    disableDefaultUI: true,
    clickableIcons: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    gestureHandling: 'cooperative',
    keyboardShortcuts: false,
    mapTypeControl: false,
    noClear: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    scrollwheel: false,
    streetViewControl: false,
    zoomControl: true
  };

  polygonOptions: Array<google.maps.PolygonOptions> = [];
  infoMarker = '';

  constructor(
    private languageCityService: LanguageCityService
  ) { }

  ngOnInit() {
    // this.mapOptions.styles = this.mapTheme;

    this.languageCityService.getMap$.subscribe(
      () => {
        this.setMap();
        this.addPolygon();
        this.mapLoaded = true;
      },
      () => {
        this.mapLoaded = false;
      }
    );
  }

  ngOnChanges() {
    if (this.address || this.reset) {
      this.addMarkerByLocation();
    }
    this.addPolygon();
  }

  openInfoWindow(marker: MapMarker) {
    // console.log(marker.marker.getTitle(), this.infoWindow);
    // this.closeTopWindow.emit(true);
    if (marker.marker.getTitle()) {
      this.infoMarker = marker.marker.getTitle();
      // this.infoWindow.infoWindow.setContent(marker.marker.getTitle());
      this.infoWindow.open(marker);
    }
  }

  setMap() {
    const bounds = new google.maps.LatLngBounds();
    if (this.markerArray?.length) {
      this.markerArray?.forEach((marker) => {
        // bounds.extend(new google.maps.Marker(marker).getPosition());
        bounds.extend(marker.position);
      });
      this.maps?.fitBounds(bounds);
    }
  }

  addPolygon() {
    // if (!this.polygonOptions?.length) { - для того щоб зона більше не перезаписувалася(підходять для Файних Льодів)
    this.polygonOptions = [];
    this.mapZonesArray?.forEach((zone) => {
      const pat = [];
      zone?.coordinates?.forEach((polygon) => {
        pat?.push({
          lat: polygon[1],
          lng: polygon[0]
        });
      });
      this.polygonOptions.push({
        ...zone,
        paths: pat,
        fillColor: `rgb(${zone?.color?.replace(/\,/g, '')})`,
        strokeColor: `rgb(${zone?.color?.replace(/\,/g, '')})`
      });
      // console.log(this.polygonOptions);
    });
    // }
  }

  // add marker by location
  addMarkerByLocation() {
    if (!this.address?.includes('null')) {
      console.log(this.address);
      let position;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: this.address }, //  + 'Львів'
        async (results: any, status) => {
          if (status === 'OK') {
            position = results[0].geometry.location;
            this.markerArray = [];
            this.markerArray.push({
              position: position.toJSON(),
              draggable: false,
              icon: './assets/icon/user.png'
            });
            this.setMap();
            this.checkIfPolygonContainsMarker(position);
          } else {
            this.currentTerminal.emit(undefined);
          }
        }
      );
    }
  }

  // check if the address falls into the some polygon
  checkIfPolygonContainsMarker(point) {
    const check = google.maps.geometry.poly;
    const selectPolyWithMarker = this.polygonOptions.find((poly) =>
      check.containsLocation(point, new google.maps.Polygon(poly))
    );
    // console.log(selectPolyWithMarker);
    this.currentTerminal.emit(selectPolyWithMarker);
  }

  ngOnDestroy() {
    this.polygonOptions = [];
  }
}
