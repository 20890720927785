import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router,
        private storage: Storage) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.storage.ready().then(() => {
            return this.storage.get('token').then(val => {
                if (val) {
                    return true;
                } else {
                    this.router.navigate(['/home']);
                    return false;
                }
            });
        });
    }
}

