import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PhotoService {
    token;
    private avatar: any = new BehaviorSubject<any>(null);
    image = this.avatar.asObservable();
    constructor(
        private storage: Storage,
        private httpClient: HttpClient
    ) {
        this.storage.ready().then(resp => {
            this.storage.get('token').then(token => {
                this.token = token;
            });
        });
    }

    changeAvatar(photo: any) {
        this.avatar.next(photo);
    }

    uploadAvatar(id, fd: object) {
        return this.httpClient
            .put('customers/' + id, fd)
            .pipe(
                map((event: any) => {
                    return event;
                }),
                catchError(val => throwError(val)));
    }

}

