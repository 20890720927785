import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RespondService } from '../../../core/services/respond.service';
import { RespondThanksPopupComponent } from '../respond-thanks-popup/respond-thanks-popup.component';
import { ContactForm7responseInterface } from '../../interfaces/contactForm7response.interface';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCityService } from '../../../core/services/language-city.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-respond-popup',
  templateUrl: './respond-popup.component.html',
  styleUrls: ['./respond-popup.component.scss'],
})
export class RespondPopupComponent implements OnInit {
  loadingEl: any;
  loading: boolean;
  formId: string;
  work = true;
  respondForm = new FormGroup({
    'your-name': new FormControl(null, [Validators.required]),
    'your-tel': new FormControl(null, [Validators.required]),
    'your-message': new FormControl(null, [Validators.required]),
    mobile_app_request: new FormControl('Мобільний Додаток')
  });

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private responseService: RespondService,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private languageCityService: LanguageCityService
  ) { }

  ngOnInit() {
    this.languageCityService.getOptions$.pipe(takeWhile(() => this.work)).subscribe(val => {
      this.formId = val?.acf?.review_form;
    });
  }

  ngOnDestroy() {
    this.work = false;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  // Create Respond
  onSubmitForm(form) {
    const dataFormatted = new URLSearchParams(form.value).toString();

    this.loadingController.create({
      message: this.translate.instant('SUBSCRIBE_POPUP.send_form')
    }).then(loadingEl => {
      this.loadingEl = loadingEl;
      this.loadingEl.present();
    });

    this.responseService.sendFeedbackForm(dataFormatted, this.formId)
      .subscribe(
        (data: ContactForm7responseInterface) => {
          this.loadingEl.dismiss();
          if (data.status === 'mail_sent') {
            this.showThanksPopup();
          } else {
            this.presentAlertError(data.message);
          }
          this.loading = false;
        },
        err => {
          this.loadingEl.dismiss();
          this.presentAlertError(err);
          this.loading = false;
        }
      );
  }

  async presentAlertError(error) {
    const alert = await this.alertController.create({
      header: this.translate.instant('RESPOND_POPUP.error'),
      message: error.message,
      buttons: [
        {
          text: this.translate.instant('RESPOND_POPUP.cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.dismiss();
          }
        }, {
          text: this.translate.instant('RESPOND_POPUP.repeate'),
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  // call on success respond post
  async showThanksPopup() {
    this.dismiss();
    const modal = await this.modalController.create({
      component: RespondThanksPopupComponent,
      cssClass: 'ios-modal-safe-top-offset'
    });
    return await modal.present();
  }
}
