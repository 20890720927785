import { Injectable, OnInit } from '@angular/core';
import { throwError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

@Injectable()

export class AuthServiceGeneral implements OnInit {
    public logged = false;
    private headers: HttpHeaders;
    private token: any;

    login$: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
        this.storage.ready().then(resp => {
            this.storage.get('token').then(token => this.token = token);
        });
    }

    ngOnInit() {
        this.headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-type': 'application/json'
        });
    }

    getToken() {
        this.storage.ready().then(resp => {
            this.storage.get('token').then(t => {
                this.token = t;
            });
        });
        return this.token;
    }

    public authenticate(body: any) {
        return this.http.post(environment.jwtEndpoint + `/token`, body, { headers: this.headers })
            .pipe(
                map((response: any) => {
                    this.storage.set('token', response.token);
                    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
                    return response;
                }),
                catchError(val => throwError(val)));
    }

    public register(data) {
        return this.http.post('customers', data, { headers: this.headers }).pipe(resp => resp);
    }

    retrivePassword(data) {
        return this.http.get(`${environment.authEndpoint}/reset?${data}`, { headers: this.headers }).pipe(response => response);
    }
}

