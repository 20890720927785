import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePoint'
})
export class DatePointPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    let pointDate;
    if (!value) {
      return value;
    }
    if (value) {
      pointDate = value;
      const replace = new RegExp('-', 'g');
      pointDate = pointDate.replace(replace, '.').substr(2, 8);
    }
    return pointDate;
  }

}
