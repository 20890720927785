import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order, WoocommerceOrderService } from 'src/app/core/services/wooapi/wooApi';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    headersJson: HttpHeaders;
    constructor(
        private http: HttpClient,
        private wooCommerce: WoocommerceOrderService
    ) { }

    sendOrder(order: any) {
        return this.wooCommerce.createOrder(order).pipe(data => data);
    }

    updateOrderStatus(order: Partial<Order>) {
        return this.wooCommerce.updateOrder(order).pipe(data => data);
    }

    getSignature(data: any, terminal_id?: string) {
        return this.http.post('wp-json/liqpay/signature', {data, terminal_id});
    }

    getCity() {
        return this.http.get('wp-json/opt/cities');
    }

    getZones(city?: string, delivery_time?: string) {
        const body = {city, delivery_time};
        return this.http.post(`wp-json/opt/zones`, body).pipe(data => data);
    }
}
