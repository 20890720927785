// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
    production: false,
    // dev api
    // origin: 'https://demo.smaki-maki.com',
    // prod api
    origin: 'https://smaki-maki.com',
    wcEndpoint: 'wp-json/wc/v3',
    wpv2Endpoint: 'wp-json/wp/v2',
    jwtEndpoint: 'wp-json/jwt-auth/v1',
    acfEndpoint: 'wp-json/acf/v3',
    authEndpoint: 'wp-json/auth',
    woocommerce: {
        // Smaki
        // dev api
        // consumer_key: 'ck_bc85a919e1ed4147aaa50ade401aff7c09a288bf',
        // consumer_secret: 'cs_5da461a2a7c5abe9d26416892f8eaa6594f95be1'
        // prod api
        consumer_key: 'ck_df44d84d9c144ab5d63e49a55e91f20118b4c16d',
        consumer_secret: 'cs_e0b7e9055bec42235dde9791276619fec46365a7'
    },
    oneSignalAppId: '39641593-f07a-4adb-bff0-46b55bdcf0b1',
    googleFirebaseSenderId: '781689067485'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
