import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!req.url.includes('v3/products') && !req.url.includes('v3/sub_cat')) {
      return next.handle(req);
    }

    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const currentTime = new Date().getTime();
    const cacheUrl = req.urlWithParams;
    const cachedResponse = this.cache.get(cacheUrl);

    if (cachedResponse?.data && cachedResponse?.expire > currentTime) {
      return of(cachedResponse.data).pipe(delay(50));
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const d = new Date();
          d.setHours(d.getHours() + 1);
          this.cache.set(cacheUrl, {
            expire: d.getTime(),
            data: event
          });
        }
      })
    );
  }
}
