import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RespondService {
  constructor(
    private http: HttpClient,
  ) { }

  sendFeedbackForm(body, formId) {
    const headers: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json, text/javascript, */*;q=0.01'
    };
    return this.http.post(`wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, body, { headers });
  }

}
