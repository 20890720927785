import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'city'
})
export class CityPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    let town;
    if (!value) {
      return value;
    }
    if (value === 'LV') {
      town = 'Lviv';
    }
    if (value === 'IF') {
      town = 'Ivano Frankivsk';
    }
    if (value === 'TP') {
      town = 'Ternopil';
    }
    return town;
  }

}
