import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Storage } from '@ionic/storage';

import { environment } from '../../../environments/environment';
import { LanguageCityService } from '../services/language-city.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    token: any;
    interceptorLang = '';
    hasDefaultData = false;
    origin = environment.origin;
    defaultData: any;
    firstRequest = true;
    language: string;

    constructor(
        private storage: Storage,
        private languageCityService: LanguageCityService
    ) {
        this.storage.ready().then(resp => {
            this.storage.get('token').then(token => {
                this.token = token;
            });
            this.storage.get('SELECTED_LANGUAGE').then(language => {
                if (language === 'ru') { language = 'uk'; }
                this.language = language;
            });
        });
    }

    private includeWooAuth(url) {
        let key = this.languageCityService.changeCity ? this.languageCityService.changeCity.key : environment.woocommerce.consumer_key;
        let secret = this.languageCityService.changeCity ? this.languageCityService.changeCity.secret : environment.woocommerce.consumer_secret;
        const wooAuth = `consumer_key=${key}&consumer_secret=${secret}`;
        const hasQuery = url.includes('?');
        let returnUrl = '';
        if (hasQuery) {
            returnUrl = wooAuth;
        } else {
            returnUrl = '?' + wooAuth;
        }
        return returnUrl;
    }

    // set interceptor data due to selected city and language
    private setInterceptorData() {
        if (this.languageCityService.changeCity) {
            if (this.hasDefaultData) { return; }
            this.defaultData = this.languageCityService.changeCity;
            this.interceptorLang = this.language ? this.language : this.defaultData.defaultLanguage;
            // if chosen lang is similar to default city lang
            if (this.interceptorLang === this.defaultData.defaultLanguage) {
                this.interceptorLang = '';
            } else {
                // if choosen lang is not similar to default city language - setting additional /ru or /ua part
                this.interceptorLang = '/' + this.interceptorLang;
            }
            this.origin = this.defaultData.defaultOrigin;
            this.hasDefaultData = true;
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestUrl = '';
        let authRequest;
        const requestToken = request.headers.get('Authorization');

        // in first request we are getting all cities from main lviv origin theme options data
        // so after it - setting interceptor data
        if (!this.firstRequest) {
            this.setInterceptorData();
        }
        this.firstRequest = false;

        if (requestToken) {
            requestUrl = `${this.origin}${this.interceptorLang}/${request.url}`;
        } else if (request.url.includes('nominatim.openstreetmap') ||
            request.url.includes('wp-content/themes/lapiec/inc/')) {
            requestUrl = request.url;
        } else {
            if (request.url.includes('jwt') || request.url.includes('ru/')) {
                requestUrl = `${this.origin}/${request.url}`;
            } else if (request.url.includes('api') ||
                request.url.includes('v2') ||
                request.url.includes('acf') ||
                request.url.includes('opt') ||
                request.url.includes('reset') ||
                request.url.includes('opt') ||
                request.url.includes('liqpay') ||
                request.url.includes('vacancies') ||
                request.url.includes('vacancy') ||
                request.url.includes('deleteAccount') ||
                request.url.includes('contact-form-7')) {
                requestUrl = `${this.origin}${this.interceptorLang}/${request.url}`;
            } else if (request.url.includes('assets')) {
                requestUrl = `${request.url}`;
            } else if (request.url.includes('iiko/orders')) {
                requestUrl = `${String(this.origin).replace('https://', 'https://')}/${request.url}`;
            }
            else {
                requestUrl =
                    `${this.origin}${this.interceptorLang}/${environment.wcEndpoint}/${request.url}${this.includeWooAuth(request.url)}`;
            }
        }

        authRequest = request.clone({
            url: requestUrl
        });

        return next.handle(authRequest)
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 0 && err.url.includes('.json')) {
                    } else if (err instanceof HttpErrorResponse && err.status === 0) {
                        /* console.log('Check Your Internet Connection And Try again Later');
                        this.alertService.presentErr(); */
                        window.addEventListener('online', () => {
                            location.reload();
                        });
                        return;
                    } else if (err instanceof HttpErrorResponse && err.status === 401) {
                    }
                    return throwError(err);
                })
            );
    }
}
