import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class LocalizationService {
    constructor(private translate: TranslateService) {
        translate.addLangs(['uk', 'ru']);
    }

    setDefaultLang(lng) {
        this.translate.setDefaultLang(lng);
    }

    // set inner app static words
    setLanguage(lng) {
        this.translate.setDefaultLang(lng);
        this.translate.use(lng);
    }
}
