import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Network } from '@ionic-native/network/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AuthGuardService } from './core/services/authguard';
import { AuthServiceGeneral } from './core/services/authservice/auth-service-general.service';
import { AppInterceptor } from './core/interceptors/app.interceptor';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';

import {
    AuthService,
    WoocommerceCategoriesService,
    WoocommerceHelperService,
    WoocommerceProductsService,
    WoocommerceTagsService
} from './core/services/wooapi/wooApi';

import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppStartResolver } from './core/services/app-start.resolver';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        IonicStorageModule.forRoot({
            name: '__SmakiMaki',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserModule,
        IonicModule.forRoot({ swipeBackEnabled: true }),
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        HttpClient,
        HttpClientModule,
        HttpClientJsonpModule,
        StatusBar,
        SplashScreen,
        AppVersion,
        DatePipe,
        WoocommerceProductsService,
        WoocommerceCategoriesService,
        WoocommerceTagsService,
        WoocommerceHelperService,
        AuthServiceGeneral,
        AuthService,
        AuthGuardService,
        Geolocation,
        Network,
        Facebook,
        FirebaseAnalytics,
        ScreenOrientation,
        AppStartResolver,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        OneSignal
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
