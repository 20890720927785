import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { CatalogService } from './catalog.service';
import { HomeService } from 'src/app/home/home.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private alertController: AlertController,
        private router: Router,
        private storage: Storage,
        private translate: TranslateService,
        private modalController: ModalController,
        private catalogService: CatalogService,
        private homeService: HomeService
    ) { }

    async presentAlert(err) {
        if (err.loaded === 0 && err.total === 0) { return; }
        let mess;
        const errCode = err.error && err.error.code ? err.error.code : err.code;
        let buttonsArr: any = ['OK'];

        if (errCode === 'registration-error-email-exists') {
            buttonsArr = [{
                text: this.translate.instant('ALERT_SERVICE.login'),
                role: 'login',
                handler: () => {
                    this.router.navigate(['/auth']);
                }
            }];
        }

        if (errCode && errCode.includes('incorrect_password')) {
            buttonsArr = [
                {
                    text: this.translate.instant('ALERT_SERVICE.recover'),
                    role: 'recover',
                    handler: () => {
                        this.router.navigate(['/auth/recover']);
                    }
                },
                {
                    text: this.translate.instant('ALERT_SERVICE.cancel'),
                    role: 'cancel',
                    handler: () => { }
                }
            ];
        }

        if (typeof (err?.error?.message) === 'string') {
            mess = err.error.message;
            if (errCode.includes('incorrect_password') && mess.includes('href')) {
                mess = mess.slice(0, mess.indexOf('<a') - 1);
            }
        } else if (typeof (err.message) === 'string') {
            mess = err.message;
        } else if (typeof (err) === 'string') {
            mess = err;
        }
        const alert = await this.alertController.create({
            header: this.translate.instant('ALERT_SERVICE.error'),
            message: mess ? mess : this.translate.instant('ALERT_SERVICE.unknown_error'),
            buttons: buttonsArr
        });

        await alert.present();
    }

    async presentErr(cb?: any) {
        const alert = await this.alertController.create({
            header: this.translate.instant('ALERT_SERVICE.error'),
            message: this.translate.instant('ALERT_SERVICE.lost_connection'),
            backdropDismiss: false
        });
        await alert.present();
        if (cb) {
            cb(alert);
        }
    }

    async presentDelivery() {
        const alert = await this.alertController.create({
            message: this.translate.instant('ALERT_SERVICE.delivered_success'),
            backdropDismiss: false,
            buttons: [{
                text: this.translate.instant('ALERT_SERVICE.ok'),
                role: 'login',
                handler: login => {
                    this.modalController.dismiss();
                }
            }]
        });
        await alert.present();
    }

    async presentThanks() {
        const alert = await this.alertController.create({
            header: this.translate.instant('ALERT_SERVICE.thanks_for_registration'),
            message: this.translate.instant('ALERT_SERVICE.sales'),
            buttons: [{
                text: this.translate.instant('ALERT_SERVICE.login'),
                role: 'login',
                handler: login => {
                    this.router.navigate(['/auth']);
                }
            }]
        });

        await alert.present();
    }

    async thanksAlert() {
        const alert = await this.alertController.create({
            header: this.translate.instant('ALERT_SERVICE.thanks_for_order'),
            message: this.translate.instant('ALERT_SERVICE.time'),
            buttons: [{
                text: this.translate.instant('ALERT_SERVICE.ok'),
                role: 'confirm',
                handler: confirm => {
                    this.router.navigate(['/home/catalogue']);
                    return confirm;
                }
            }]
        });

        await alert.present();
    }

    async doneMessage(status) {
        const alert = await this.alertController.create({
            message: status,
            buttons: ['OK']
        });

        await alert.present();
    }

    logOut() {
        this.storage.remove('user');
        this.homeService.setUserInStorage(null);
        this.storage.remove('avatar');
        this.storage.remove('cart');
        this.storage.remove('stick_ginger');
        this.catalogService.getCartList([]);
        this.storage.remove('FavoriteList');
        this.catalogService.getFavoritList([]);
        this.storage.ready().then(() => {
            this.storage.get('user').then(data => {
                return data;
            });
        });
        this.storage.remove('token');
        this.router.navigate(['/auth']);
    }

    async presentAlertConfirm(headerMessage, description) {
        const alert = await this.alertController.create({
            header: headerMessage,
            message: description,
            buttons: [
                {
                    text: this.translate.instant('ALERT_SERVICE.cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        return;
                    }
                }, {
                    text: this.translate.instant('ALERT_SERVICE.ok'),
                    handler: () => {
                        this.logOut();
                    }
                }
            ]
        });

        await alert.present();
    }


}
