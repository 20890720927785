import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Platform, PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { minimumOneNumber } from "../../validators/email.validator";
import { finalize } from "rxjs/operators";
import { OrderService } from "src/app/home/order/order.service";
import { PersonalService } from "src/app/home/personal/personal.service";
import { AlertService } from "src/app/core/services/alert.service";

@Component({
  selector: "app-delivery-zone-popover",
  templateUrl: "./delivery-zone-popover.component.html",
  styleUrls: ["./delivery-zone-popover.component.scss"],
})
export class DeliveryZonePopoverComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() errorText: string;
  @Input() successText: string;
  @Input() iikoCity: string = null;
  @Input() outCities: any;
  @Input() selfPickupAddresses: Array<any>;
  @Input() terminals: Array<any>;
  @Input() closeEnable: boolean;
  loading: boolean = true;
  addressForm: FormGroup;
  mapZonesArray: Array<any>;
  streets: Array<any>;
  states: any;
  address: string;
  terminalWork: any;
  reset: boolean = false;

  backBtnSubscription: Subscription;
  valueChangesCity: Subscription;

  constructor(
    private platform: Platform,
    private popoverController: PopoverController,
    private orderService: OrderService,
    private personalService: PersonalService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.addressForm = new FormGroup({
      deliveryMethod: new FormControl("courier", [Validators.required]),
      address: new FormGroup({
        city: new FormControl(null, Validators.required),
        street: new FormControl(
          { value: null, disabled: true },
          Validators.required
        ),
        house: new FormControl(null, [
          Validators.required,
          Validators.pattern(minimumOneNumber),
        ])
      }),
    });

    this.getCity(this.iikoCity);
    this.getZones(this.iikoCity);

    this.valueChangesCity = this.addressForm.get('address').get('city').valueChanges.subscribe((val) => {
      this.addressForm.get('address').get('street').reset();
      if (val) {
        this.streets = this.states[val];
      }
      this.addressForm.get('address').get('street').enable();
    });
  }

  ionViewWillEnter() {
    this.backBtnSubscription = this.platform.backButton.subscribeWithPriority(
      9997,
      () => {
        if (this.closeEnable) {
          this.closePopover();
        }
      }
    );
  }

  getCity(iikoCity = '') {
    this.loading = true;
    this.personalService.getCity(iikoCity)
      .pipe(finalize(async () => {
        this.loading = false;
      }))
      .subscribe(
        data => {
          this.states = data;
          if (this.addressForm.get('address').get('city').value) {
            this.streets = this.states[this.addressForm.get('address').get('city').value];
            this.addressForm.get('address').get('street').enable();
          }
        },
        err => {
          this.alertService.presentAlert(err);
        }
      );
  }

  //  Зміна/Витяжка зон
  getZones(city, deliveryTime?) {
    let time;
    if (deliveryTime?.detail?.value) {
      time = new Date(deliveryTime?.detail?.value).toTimeString().split(' ')[0].slice(0, 5);
    }
    if (city) {
      this.loading = true;
      this.orderService.getZones(city, time)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          (data: any) => {
            this.mapZonesArray = data?.file ? data?.file : [];
          },
          (error: any) => {
            this.mapZonesArray = [];
          }
        );
    }
  }

  changeDeliveryMethod(event: any) {
    if (event?.detail?.value === 'courier') {
      this.addressForm?.get('selfPickupAddress').reset();
      this.addressForm?.get('selfPickupAddress').clearValidators();
      this.addressForm?.get('address')?.get('city').setValidators([Validators.required]);
      this.addressForm?.get('address')?.get('street').setValidators([Validators.required]);
      this.addressForm?.get('address')?.get('house').setValidators([Validators.required, Validators.pattern(minimumOneNumber)]);
    } else if (event?.detail?.value === 'self_pickup') {
      this.addressForm?.get('address')?.get('city').reset();
      this.addressForm?.get('address')?.get('street').reset();
      this.addressForm?.get('address')?.get('house').reset();
      this.addressForm?.get('address')?.get('city').clearValidators();
      this.addressForm?.get('address')?.get('street').clearValidators();
      this.addressForm?.get('address')?.get('house').clearValidators();
      this.addressForm?.get('selfPickupAddress').setValidators([Validators.required]);
    }
    this.addressForm?.get('address')?.get('city').updateValueAndValidity();
    this.addressForm?.get('address')?.get('street').updateValueAndValidity();
    this.addressForm?.get('address')?.get('house').updateValueAndValidity();
    this.addressForm?.get('selfPickupAddress').updateValueAndValidity();
  }
  
  // Інформація про точку самовивозу яку вибрав користувач
  selectSelfPickupAddress(event) {
    if (this.selfPickupAddresses?.length) {
      // this.terminalId = this.selfPickupAddresses?.find(address => address?.address === event?.detail?.value)?.terminal_id;
    }
  }

  // Інформація про зону в яку попав користувач
  async getInfoForZone(event) {
    this.terminalWork = '';
    this.terminalWork = this.terminals?.find((terminal: any) => terminal?.terminal_id === event?.terminal_id)?.status;
  }

  checkAddress() {
    this.address = this.addressForm?.get('address')?.get('street')?.value + ', ' + this.addressForm?.get('address')?.get('house')?.value + ', ' +
      this.addressForm?.get('address')?.get('city')?.value + (this.addressForm?.get('address')?.get('city')?.value !== this.iikoCity ? (', ' + this.iikoCity + ' область') : '')
    this.reset = !this.reset;
  }

  async closePopover() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
    if (this.valueChangesCity) {
      this.valueChangesCity.unsubscribe();
    }
    await this.popoverController.dismiss();
  }

  ionViewDidLeave() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
    if (this.valueChangesCity) {
      this.valueChangesCity.unsubscribe();
    }
  }
}
